import React from 'react';
import styled from 'styled-components/macro';
import {
  WhiteBox,
  FWSection,
  InfoBoxesWrapper,
  Intro,
  ContentWrapper,
} from '../styles/mainStyles';
import { Link } from 'react-router-dom';

import { Quote } from '../components/Quote';
import { Case } from '../components/Case';
import { CaseWrapper } from '../components/Project';
import { Footer } from '../components/Footer';

export const Dev = ({ mainColor, secondaryColor }) => {
  const moveToPageHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <FWSection style={{ backgroundColor: secondaryColor }}>
      <ContentWrapper>
        <h1 style={{ color: mainColor }}>Utveckling</h1>
        <Intro mainColor={mainColor}>
          Jag hjälper till från början till slut, från idé till produktion och
          lansering. Jag kopplar domän, lägger upp epost-adresser och ser till
          att allting funkar.
        </Intro>
        <InfoBoxesWrapper>
          <WhiteBox mainColor="var(--color-brightRed)">
            <h2>I varje projekt ingår</h2>
            <ul>
              <li>Fullt redigerbart innehåll</li>
              <li>Skräddarsydda sektioner</li>
              <li>Responsiv design</li>
              <li>SEO</li>
              <li>Genomgång av publiceringsverktyg</li>
            </ul>
          </WhiteBox>

          <div className="top-text">
            <h2>Tech</h2>
            <p style={{ lineHeight: '1.6' }}>
              Jag bygger främst i React (med grund i HTML/CSS/Styled
              Components/JavaScript), med data som servas över headless
              CMS-tjänsten Sanity och hosting genom Netlify. Serverless och
              headless med fokus på hållbar, ren kod, tipp topp prestanda och
              skräddarsydd funktionalitet.
            </p>
            <h2 style={{ marginTop: '30px' }}>Kompetens & filosofi</h2>
            <p style={{ lineHeight: '1.6' }}>
              Som frontendare med designbakgrund kan jag garantera en pixel
              perfect leverans och en förståelse för UX och UI. Är det något som
              jag inte kan lösa på rak arm hittar jag en lösning, lär mig
              tekniken och använder den utan problem. Jag är grym på att googla,
              och har hittills inte stött på något jag inte kunnat lösa genom
              några sökningar.{' '}
            </p>
          </div>
        </InfoBoxesWrapper>

        <Case
          color={mainColor}
          name="CDCUL - Consumer Demand for Circular Urban Living"
          client="RISE"
          task="Webb & Design"
          imgSrc="./assets/cookie-cdcul.png"
          tech="Wordpress / Elementor"
          url="https://cdcul.eu/"
        />
        <Case
          color={mainColor}
          name="Skyresponse"
          client="Skyresponse / Smarkit"
          task="Webb"
          imgSrc="./assets/skyresponse.png"
          tech="Hubspot CMS – HTML/CSS/JS/HUBL"
          url="https://skyresponse.com/"
        />
        <Case
          color={mainColor}
          name="Seatons Strand"
          client="Samfälligheten Seatons Strand"
          task="Logotyp, identitet, webb"
          imgSrc="./assets/seatons-strand.jpg"
          tech="Frontend: React + Styled Components / Backend: Sanity"
          url="https://seatonsstrand.se/"
        />
        <Case
          color={mainColor}
          name="Caroline Borg"
          client="Caroline Borg - stylist/fotograf/storyteller"
          task="Logotyp, identitet, webb"
          imgSrc="./assets/caroline-borg.png"
          tech="Frontend: React + Styled Components / Backend: Sanity"
          url="https://carolineborg.se"
        />

        <CaseWrapper>
          <div className="case-text-wrapper">
            <h3>
              <span className="case-title">
                Technigo Frontend Bootcamp Projekt
              </span>
            </h3>

            <p>
              <span style={{ display: 'block' }}>
                Ett urval av de 21 projekt jag byggde under utbildningens 24
                veckor.
              </span>
            </p>

            <p>
              <BootcampLink
                to="/utveckling/bootcamp"
                onClick={moveToPageHandler}
              >
                → Gå till projekt
              </BootcampLink>
            </p>
          </div>
          <img alt="" src="./assets/bootcamp.jpg" />
        </CaseWrapper>

        <CaseWrapper>
          <div className="case-text-wrapper">
            {/* <h3>
              <span className="case-title">
                Technigo Frontend Bootcamp Projekt
              </span>
            </h3> */}

            <p>
              <span style={{ display: 'block' }}>
                Vill du se fler case? Större delen av mina utvecklingsprojekt
                ligger under konsult, då jag främst arbetat som konsult för
                webbprojekt.
              </span>
            </p>

            <p>
              <BootcampLink to="/konsult" onClick={moveToPageHandler}>
                → Gå till konsult
              </BootcampLink>
            </p>
          </div>
        </CaseWrapper>

        <Quote
          margins="20px 50px"
          sectionColor={mainColor}
          quote='"Headless med fokus på hållbar, ren kod, tipp topp prestanda och fullt skräddarsydd funktionalitet"'
        />
        {/* <Case color={mainColor} name="Teenytiny" client="Teenytiny (co-owner)" task="Logotyp, identitet, webb" imgSrc="./assets/teenytiny-insta-girl-biz.png" url="https://www.instagram.com/teenytinybrand/" /> */}
      </ContentWrapper>
      <Footer />
    </FWSection>
  );
};

const BootcampLink = styled(Link)`
  &:hover {
    color: var(--color-brightRed);
  }
`;
