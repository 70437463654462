import React from 'react';

import {
  WhiteBox,
  FWSection,
  InfoBoxesWrapper,
  Intro,
  ContentWrapper,
  SectionImage,
  LogoSection,
} from '../styles/mainStyles';
import { Quote } from '../components/Quote';
import { Footer } from '../components/Footer';
import { Case } from '../components/Case';

export const DesignEN = ({ mainColor, secondaryColor }) => {
  return (
    <FWSection style={{ backgroundColor: secondaryColor }}>
      <ContentWrapper>
        <h1 style={{ color: mainColor }}>Design</h1>
        <Intro mainColor={mainColor}>
          No matter if you need a logotype, imagery or a color scheme with
          accompanying typography, I will create a cohesive, structured profile
          for your brand.
        </Intro>
        <InfoBoxesWrapper>
          <WhiteBox mainColor="var(--color-lavendarLilac)">
            <h2>My services</h2>
            <ul>
              <li>Visual Identity</li>
              <li>Social media content</li>
              <li>Digital transformation</li>
              <li>Marketing material</li>
              <li>Editorial design</li>
              <li>Books</li>
              <li>Business Reports</li>
            </ul>
          </WhiteBox>

          <div className="top-text">
            <h2>Philosophy</h2>
            <p style={{ lineHeight: '1.6' }}>
              When I work with design and branding, I do it intuitively but
              targeted and with a firm hand. I'm passionate about finding the
              core of a brand and put it into words, colors, fonts and images.
            </p>
            <p style={{ lineHeight: '1.6', marginTop: '10px' }}>
              I avoid heavy, abstract and expensive presentations, and more with
              sketch work, quick iterations and feedback.
            </p>
            <h2 style={{ marginTop: '30px' }}>Tools</h2>
            <p style={{ lineHeight: '1.6' }}>
              Depending on media, I prefer Figma, Photoshop, InDesign or
              Illustrator.
            </p>
          </div>
        </InfoBoxesWrapper>

        <LogoSection>
          <h2>Selection of clients</h2>
          <div>
            <img src="./assets/logos/bonnier-svart.png" />
            <img src="./assets/logos/castellum-svart.png" />
            <img src="./assets/logos/hsb-svart.png" />
            <img src="./assets/logos/mama-svart.png" />
            <img src="./assets/logos/pe-svart.png" />
            <img src="./assets/logos/manpower-svart.png" />
            <img src="./assets/logos/plaza-svart.svg" />
            <img src="./assets/logos/spoon-svart.png" />
            <img src="./assets/logos/structsales-svart.png" />
            <img src="./assets/logos/wwl-svart.png" />
            <img src="./assets/logos/zooma-svart.png" />
            <img src="./assets/logos/zwapgrid-svart.png" />
          </div>
        </LogoSection>

        <SectionImage
          alt="Previous work"
          marginTop="50px"
          src="./assets/karin-nordkvist-earlier-work.jpg"
        />
        <Quote
          margins="100px 50px"
          sectionColor="var(--color-lavendarLilac)"
          quote='"Karin is very easy to work with, she adapts her way of working to the situation and has an infallible eye for design. Karin is a good listener, has a strong sense of current trends, and is a team player. I would strongly recommend her."'
          name="Vendela Nylund, Art Director/mama magazine - Bonnier"
        />

        <Case
          color={mainColor}
          name="Plaza Kvinna"
          client="Plaza Publishing Group"
          task="Art direction/layout"
          imgSrc="./assets/plaza-kvinna.jpg"
          text="Production and layout of fashion-, beauty- and lifestyle magazine Plaza Kvinna, 7 issues per year."
        />
        <Case
          color={mainColor}
          name="Caroline Borg"
          client="Caroline Borg - stylist/fotograf/storyteller"
          task="Logotype, identity, web"
          imgSrc="./assets/caroline-borg.png"
          tech="Frontend: React + Styled Components / Backend: Sanity"
          url="https://carolineborg.se"
          text="I wanted to enhance Caroline's fantastic photos and that strongly affected the design of this page. It's clean and elegant with a characteristic typography and a subtle color scheme that brings out the images."
        />
        {/* <Case
          color={mainColor}
          name="Tvillingpodden"
          client="Jessica & Angelica Lagergren, Tvillingpodden"
          task="Logotype, identity"
          imgSrc="./assets/tvillingpodden.jpg"
          text="I had great fun creating this playful profile for the hilarious duo Angelica and Jessica that run the podcast Tvillingpodden. I wanted to let their words speak, with a colorful background."
        /> */}
        <Case
          color={mainColor}
          name="Hemma i HSB"
          client="Spoon/HSB"
          task="Art Direction/design"
          imgSrc="./assets/cookie-spoon-hsb.png"
          text="Still one of my absolute favorite jobs. Leading the creative direction for the Hemma i HSB and HSB Uppdraget magazines."
        />
        <Case
          color={mainColor}
          name="Vattnet Går"
          client="Nina Campioni/Vattnet går"
          task="Identity, webb, color scheme & typography"
          imgSrc="./assets/vattnet-gar.jpg"
          url="https://instagram.com/vattnetgar"
          text="It all begain with a newsletter, followed by a graphic profile, a set of different logotypes for the different podcasts, and finally a website."
        />
        {/* <Case color={mainColor} name="Doula Lagergren" client="Angelica Lagergren" task="Logo & Identitet" imgSrc="./assets/doula-lagergren-3.jpg" /> */}
        <Quote
          margins="0 50px 50px"
          sectionColor="var(--color-lavendarLilac)"
          quote='"Before we started working together, Karin made her research, which made the work run quick and smooth. It was obvious that she understood my brand and target audience, both in the design and in our discussions. I´m very happy with her work!"'
          name="Nina Campioni, founder of Vattnet Går"
        />
        <Case
          color={mainColor}
          name="Castellum"
          client="Castellum AB"
          task="Identitet"
          imgSrc="./assets/castellum.png"
          text="Design work where I focused mainly to make their graphic profile cohesive for the entire company. I designed everything from signage, financial reports, reference material for the marketing group and much more."
        />
        <Case
          color={mainColor}
          name="Teenytiny"
          task="Branding/web shop/social medias/strategy"
          imgSrc="./assets/teenytiny-1.jpg"
          text="Me and a friend created a web shop with products targeted towards parents with small children, and with an interest in the environment. The site was featured in several of Sweden´s biggest interior design magazines."
          url="https://instagram.com/teenytinybrand/"
        />
        <Case
          color={mainColor}
          name="Mama"
          client="Bonnier"
          task="Designer/image editor"
          imgSrc="./assets/mama.jpg"
          text="For about 4 years I worked on mama Magazine, doing design work, layout, photo editing, pre print work as well as working as image editor for mama.nu."
        />
      </ContentWrapper>
      <Footer />
    </FWSection>
  );
};
