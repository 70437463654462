import React from 'react';
import styled from 'styled-components/macro';
import { FWSection } from '../styles/mainStyles';

import { Footer } from '../components/Footer';

export const Start = ({ mainColor, secondaryColor }) => {
  return (
    <StartFWSection style={{ backgroundColor: secondaryColor }}>
      <img
        onClick={() => Navigator.vibrate()}
        alt="Cookie Works logo"
        src="./assets/cookie-logo-solo-white.svg"
        style={{
          width: '30%',
          marginLeft: '30px',
          position: 'relative',
          zIndex: '1',
        }}
      />
      <h1>Art Director / Graphic Designer / Frontend Developer</h1>
      <div
        className="block-1"
        style={{
          width: '100%',
          display: 'flex',
          position: 'relative',
          height: '100%',
        }}
      ></div>

      <div
        className="block-2"
        style={{
          display: 'flex',
          position: 'relative',
          marginTop: '60px',
          transform: 'translateX(-30px)',
        }}
      >
        <div className="wave">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            fill="#fff"
          >
            <path d="M0 0v90.2C49.7 99.9 105 82 160 65c75.5-23.3 145.5-22.4 222-3 63 16 119 14 173-8 79.5-32.4 156.2-27.6 240-10 82.6 17.4 143-1 205-31.7V0H0Z"></path>
          </svg>
        </div>
        <div className="content">
          <img
            style={{
              minWidth: '250px',
              maxHeight: '250px',
              objectFit: 'cover',
              borderRadius: '100%',
            }}
            src="./assets/karin-nordkvist-2022.jpg"
          />

          <div className="info-box">
            <p>
              Hej! Jag heter Karin. Jag kan hjälpa dig med en ny logga, grafisk
              profil, en ny hemsida, webbshop, landningssidor, epostmallar,
              årsredovisningar, presentationer, böcker, tidningar, foldrar,
              skyltar, word-mallar, visitkort, butiksmaterial, banners och
              mycket mer.
            </p>
            <p>
              <img
                style={{ width: '20px', marginRight: '10px' }}
                src="./assets/arrow-right.svg"
              />
              <a
                style={{ color: '#F499F8' }}
                href="mailto:karin@cookieworks.se"
              >
                Kontakta mig
              </a>{' '}
              så pratar vi mer!
            </p>
          </div>
        </div>

        <div className="block-3">
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              position: 'relative',
              zIndex: '200',
            }}
          >
            <img src="./assets/cookie-plaza-01.jpg" />
            <img src="./assets/cookie-kaptena-01.jpg" />
            <img src="./assets/cookie-rodakorset-01.jpg" />
            <img src="./assets/castellum.png" />
            <img src="./assets/cookie-cdcul.png" />
            <img src="./assets/caroline-borg.png" />
            <img src="./assets/peaccounting.jpg" />
            <img src="./assets/cookie-vattnet-gar-01.jpg" />
            <img src="./assets/cookie-plaza-02.jpg" />
            {/* <img src="./assets/cookie-mama-01.jpg" /> */}
            <img src="./assets/cookie-tvillingpodden-01.jpg" />
            <img src="./assets/cookie-spoon-01.jpg" />
            <img src="./assets/teenytiny-1.jpg" />
          </div>
        </div>
      </div>

      {/* <p>Tjej med many talents. Rotlös och headless, med tre ben!</p> */}
      <Footer />
    </StartFWSection>
  );
};

const StartFWSection = styled(FWSection)`
  overflow: hidden;
  background-color: #8ec5fc;
  background-image: linear-gradient(
    120deg,
    #f499f8 0%,
    #c8c3fc 66%,
    #f499f8 0%,
    #c8c3fc 66%
  );
  background-size: 400%;
  animation: gradient-bg 5s infinite;
  padding: 140px 30px 0;
  min-height: 100vh;
  justify-content: flex-start;

  h1 {
    font-size: 42px;
    margin: 50px 0 100px 30px;
    max-width: 50vw;
    font-weight: 700;
  }

  @media (max-width: 700px) {
    padding: 100px 30px 0;

    h1 {
      max-width: 90%;
    }
  }

  @media (max-width: 1300px) {
    h1 {
      font-size: 28px;
    }
  }

  .block-1 {
    max-width: 76%;
    padding: 0 30px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 30px;
    /* transform: rotate(-5deg); */

    .hero-img {
      width: calc(33.33333% - 20px);
      object-fit: cover;
      border: 2px solid;
    }
  }

  .block-2 {
    position: relative;
    flex-direction: column;
    gap: 0;
    width: calc(100% + 60px);
    /* margin-bottom: 350px; */

    .wave {
      position: absolute;
      bottom: 100%;
      width: 100%;
      height: auto;
      transform: scale(-1);
      z-index: -10;
    }

    .content {
      width: 100%;
      background: #fff;
      padding: 70px 50px 100px;
      display: flex;
      align-items: center;
      gap: 50px;
      padding-right: 370px;
    }

    .info-box p {
      font-size: 28px;
      line-height: 1.4;
    }

    .hero-img {
      width: calc(33.33333% - 20px);
      object-fit: cover;
      border: 2px solid;
    }

    @media (max-width: 1300px) {
      .wave {
        bottom: 99.9%;
      }
      .content {
        flex-direction: column;
      }
      .info-box p {
        font-size: 24px;
      }
    }

    @media (max-width: 780px) {
      .info-box p {
        font-size: 18px;
      }
    }

    @media (max-width: 700px) {
      .content {
        padding: 50px 50px 80px;
        gap: 20px;
      }
    }
  }

  .block-3 {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: #fff;

    img {
      width: 33.3333%;
      height: 250px;
      object-fit: cover;
    }
  }

  @keyframes gradient-bg {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

const ContentWrapper = styled.div`
  width: 76%;
  height: calc(100vh - 250px - 48px);
  position: relative;
  display: flex;

  h1 {
    font-size: 156px;
    font-family: var(--font-secondary);
    display: block;
  }

  /* img {
  width: 500px;
  position: absolute;
  right: 100px;
} */

  p {
    font-family: var(--font-secondary);
    max-width: 30%;
    position: absolute;
    right: 100px;
    text-align: right;
    top: 300px;
    line-height: 1.4;
  }

  .one-woman-show {
    margin: 0 0 0 50px;
    font-size: 300px;
    font-family: var(--font-secondary);
    display: block;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: calc(100vh - 180px - 24px);
    padding-top: 40px;

    h1 {
      font-size: 42px;
    }

    img {
      width: 60%;
      max-width: 600px;
      left: 40px;
    }

    p {
      max-width: 60%;
      top: 340px;
      left: 40px;
      font-size: 24px;
    }
  }

  @media (max-width: 700px) {
    width: 100%;
    height: calc(100vh - 190px - 30px);
    padding-top: 40px;

    h1 {
      font-size: 42px;
    }

    img {
      width: 80%;
      /* max-width: 280px; */
      right: 40px;
      top: -40px;
    }

    p {
      max-width: 80%;
      top: 280px;
      right: 40px;
      font-size: 24px;
    }
  }

  @media (max-width: 400px) {
    width: 100%;
    height: calc(100vh - 190px - 30px);
    padding-top: 40px;

    h1 {
      font-size: 42px;
    }

    img {
      width: 80%;
      right: 20px;
    }

    p {
      max-width: 100%;
      top: 200px;
      right: 20px;
      font-size: 18px;
    }
  }
`;
