import React from 'react';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';

export const Case = ({
  color,
  name,
  client,
  task,
  imgSrc,
  tech,
  url,
  text,
}) => {
  const location = useLocation();

  return (
    <CaseWrapper>
      <div className="case-text-wrapper">
        <h3>
          <span style={{ background: color, color: '#fff' }}>Case:</span>{' '}
          <span className="case-title">{name}</span>
        </h3>

        <p>
          {client ? (
            <span style={{ background: color, color: '#fff' }}>
              {location.pathname.includes('/en') ? 'Client:' : 'Kund:'}
            </span>
          ) : (
            ''
          )}
          {client ? <span> {client}</span> : ''}
        </p>

        <p>
          <span style={{ display: 'block' }}>
            <span style={{ background: color, color: '#fff' }}>
              {location.pathname.includes('/en') ? 'Task:' : 'Uppdrag:'}
            </span>{' '}
            {task}
          </span>
        </p>

        <p>
          {tech ? (
            <span style={{ background: color, color: '#fff' }}>Tech:</span>
          ) : (
            ''
          )}
          <span style={{ display: '' }}> {tech}</span>
        </p>

        <p>
          {url ? (
            <span style={{ background: color, color: '#fff' }}>URL:</span>
          ) : (
            ''
          )}
          <CaseLink
            color={color}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {url ? ` ${url}` : ''}
          </CaseLink>
        </p>

        <p>
          {text ? (
            <span style={{ background: color, color: '#fff' }}>
              {location.pathname.includes('/en') ? 'About:' : 'Om:'}
            </span>
          ) : (
            ''
          )}
          {text ? ` ${text}` : ''}
        </p>
      </div>
      <img alt="" src={imgSrc} style={{ position: 'relative' }} />
    </CaseWrapper>
  );
};

const CaseWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-main);
  line-height: 1.4;

  h3 {
    font-size: 28px;
    margin-bottom: 30px;
  }

  p {
    margin: 10px 0;
    font-family: var(--font-secondary);
  }

  div {
    width: 45%;
  }

  img {
    width: 50%;
    height: 350px;
    padding-bottom: 6%;
    object-fit: cover;
    position: relative;
  }

  .case-title {
    display: block;
    font-weight: 400;
  }

  .case-text-wrapper {
    padding-bottom: 6%;
  }

  @media (max-width: 1200px) {
    flex-direction: column-reverse;

    img {
      align-self: flex-start;
      width: 100%;
      height: auto;
      padding-bottom: 20px;
    }

    div {
      width: 100%;
    }

    .case-title {
      display: inline;
    }

    .case-text-wrapper {
      padding-bottom: 8%;
    }

    h3 {
      margin-bottom: 20px;
    }

    p {
      margin: 5px 0;
    }
  }

  @media (max-width: 600px) {
    img {
      width: 100%;
    }

    .case-text-wrapper {
      padding-bottom: 10%;
    }
  }
`;

const CaseLink = styled.a`
  &:hover {
    color: ${(props) => props.color};
  }
`;
