import React from 'react';

import {
  WhiteBox,
  FWSection,
  InfoBoxesWrapper,
  Intro,
  ContentWrapper,
} from '../styles/mainStyles';
import { Quote } from '../components/Quote';
import { Case } from '../components/Case';
import { Footer } from '../components/Footer';

export const KonsultEN = ({ mainColor, secondaryColor }) => {
  return (
    <FWSection style={{ backgroundColor: secondaryColor }}>
      {/* <HeroBlock>Jag hjälper till från början till slut, från idé till produktion och lansering. Jag kopplar domän, lägger upp epost-adresser och ser till att allting funkar.</HeroBlock> */}
      <ContentWrapper>
        <h1 style={{ color: mainColor }}>Consulting</h1>
        <Intro mainColor={mainColor}>
          As a graphic designer and a frontend developer I can offer you a wide
          selection of tasks. I can help you with web development as well as
          design, or both. Smooth and effective, in the way that works best for
          you and your clients.
        </Intro>
        <InfoBoxesWrapper>
          <WhiteBox mainColor={mainColor}>
            <h2>My services</h2>
            <ul>
              <li>Frontend Web development</li>
              <li>Hubspot & HubDB development</li>
              <li>Graphic design/Art direction</li>
              <li>Contentproduction</li>
              <li>Short, snappy projects</li>
              <li>By the hour</li>
              <li>Multifaceted projects</li>
            </ul>
          </WhiteBox>

          <div className="top-text">
            <h2>Competence</h2>
            <p style={{ lineHeight: '1.6' }}>
              My competence features a wide spectrum from design and
              creativeness, to logic and development. I'm passionate about
              creativity, ideas make me thrive and I learn fast, while working.
            </p>

            <h2 style={{ marginTop: '30px' }}>Companies I've worked with</h2>
            <p style={{ lineHeight: '1.6' }}>
              Structsales, QueensLab, Technigo, Manpower, &frankly, Åkerströms,
              Zooma, PE Accounting, Goava, QMatic, Bufab and others.
            </p>
          </div>
        </InfoBoxesWrapper>

        <Quote
          margins="100px 50px"
          sectionColor={mainColor}
          quote='"We worked together with Karin when we were to create our new site. I appreciate that Karin is fast, accommodating, professional and hearty. I would work with Karin again!"'
          name="CAMILLA DRANGEL, Market Specialist at PE ACCOUNTING"
        />

        <Case
          color={mainColor}
          name="Kaptena"
          client="Kaptena/Smarkify"
          task="Graphic profile and full webpage created with Elementor/Wordpress."
          imgSrc="./assets/cookie-kaptena-02.png"
          tech="Figma, Wordpress/Elementor, CSS"
        />
        <Case
          color={mainColor}
          name="Frontend Bootcamp + UX Bootcamp"
          client="Technigo"
          task="Code coach, designer"
          imgSrc="./assets/technigo-2.jpg"
          text="Educate and coach the students in code and design. Create and design working tools and materials."
          tech="HTML/CSS/Javascript, Node.js, MongoDB, Figma"
        />
        <Case
          color={mainColor}
          name="CMS development & templating"
          client="Structsales / various clients"
          task="Developer & designer"
          imgSrc="./assets/cookie-manpower.png"
          text="Production of webpages/landing pages/email templates as well as design work for various clients."
          tech="Hubspot/HubL/HubDB, HTML/CSS/Javascript"
        />
        {/* <Case
          color={mainColor}
          name="&frankly - Knowledgebank"
          client="&frankly/Structsales"
          task="Knowledgebank in Hubspot"
          imgSrc="./assets/frankly.jpg"
          tech="Hubspot/HubL/HubDB, Javascript"
        /> */}
        <Case
          color={mainColor}
          name="Qmatic"
          client="Qmatic/Zooma"
          task="Full webpage in Hubspot"
          imgSrc="./assets/qmatic.jpg"
          tech="Hubspot/HubL/HubDB, Javascript, SCSS, Vue"
        />
        <Case
          color={mainColor}
          name="GoHybrid"
          client="GoHybrid/QueensLab"
          task="Full webpage in Hubspot"
          imgSrc="./assets/gohybrid.jpg"
          tech="Hubspot/HubL/HubDB, Javascript"
        />
        <Case
          color={mainColor}
          name="PE Accounting"
          client="PE Accounting/QueensLab"
          task="Full webpage in Hubspot"
          imgSrc="./assets/peaccounting.jpg"
          tech="Hubspot/HubL/HubDB, Javascript"
        />
      </ContentWrapper>
      <Footer />
    </FWSection>
  );
};
