import React from 'react';
import styled from 'styled-components/macro';

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterWrapper>
      <span style={{ textAlign: 'center' }}>
        Copyright © Karin Nordkvist - Cookie D+D AB - {currentYear}
      </span>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  position: relative;
  bottom: 0;
  width: calc(100% + 120px);
  min-height: 200px;
  text-align: center;
  height: 20px;
  font-size: 12px;
  background: #ff92f4;
  transform: translate(-60px, 0);
  margin: 0;
  padding: 130px 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 50px; */

  &:before {
    content: '';
    width: 80px;
    height: 80px;
    position: absolute;
    border-radius: 50%;
    background: #ff92f4;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-image: url(./assets/cookie.svg);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
  }

  @media (max-width: 700px) {
    text-align: left;
  }
`;
