import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Start } from './pages/Start.js';
import { StartEN } from './pages/StartEN.js';
import { DevSchoolWork } from './pages/DevSchoolWork.js';
import { DevSchoolWorkEN } from './pages/DevSchoolWorkEN.js';
import { Dev } from './pages/Dev.js';
import { DevEN } from './pages/DevEN.js';
import { Design } from './pages/Design.js';
import { DesignEN } from './pages/DesignEN.js';
import { Konsult } from './pages/Konsult.js';
import { Summer } from './pages/Summer.js';
import { KonsultEN } from './pages/KonsultEN.js';
import { Navigation } from './components/Navigation.js';

const App = () => {
  return (
    <Router>
      <Navigation />
      <CookieImage src="./assets/cookie.svg" />
      <Routes>
        <Route
          element={<Start mainColor="#F499F8" secondaryColor="#eae4dc" />}
          path="/"
          exact
        />
        <Route
          element={
            <Dev
              mainColor="var(--color-brightRed)"
              secondaryColor="var(--color-lightPink)"
            />
          }
          path="utveckling"
          exact
        />
        <Route
          element={
            <DevSchoolWork
              mainColor="var(--color-brightRed)"
              secondaryColor="var(--color-lightPink)"
            />
          }
          path="utveckling/bootcamp"
          exact
        />

        <Route
          element={
            <Design
              mainColor="var(--color-lavendarLilac)"
              secondaryColor="var(--color-softYellow)"
            />
          }
          path="design"
          exact
        />
        <Route
          element={
            <Konsult
              mainColor="var(--color-vividBlue)"
              secondaryColor="var(--color-lightLilac)"
            />
          }
          path="konsult"
          exact
        />
        <Route
          element={
            <Summer
              mainColor="var(--color-summerOrange)"
              secondaryColor="var(--color-summerBlue)"
            />
          }
          path="sommar24"
          exact
        />

        {/* ENGLISH */}
        <Route
          element={<StartEN mainColor="#e4ff24" secondaryColor="#eae4dc" />}
          path="en"
          exact
        />
        <Route
          element={
            <DevEN
              mainColor="var(--color-brightRed)"
              secondaryColor="var(--color-lightPink)"
            />
          }
          path="en/development"
          exact
        />
        <Route
          element={
            <DevSchoolWorkEN
              mainColor="var(--color-brightRed)"
              secondaryColor="var(--color-lightPink)"
            />
          }
          path="en/development/bootcamp"
          exact
        />
        <Route
          element={
            <DesignEN
              mainColor="var(--color-lavendarLilac)"
              secondaryColor="var(--color-softYellow)"
            />
          }
          path="en/design"
          exact
        />
        <Route
          element={
            <KonsultEN
              mainColor="var(--color-vividBlue)"
              secondaryColor="var(--color-lightLilac)"
            />
          }
          path="en/consulting"
          exact
        />
      </Routes>
    </Router>
  );
};

export default App;

const CookieImage = styled.img`
  position: fixed;
  bottom: -15px;
  right: 40px;
  width: 80px;
  z-index: 300000000;
  animation: rotate 4s ease-in-out infinite alternate;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;
