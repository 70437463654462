import React from 'react';

import {
  WhiteBox,
  FWSection,
  InfoBoxesWrapper,
  Intro,
  ContentWrapper,
  SectionImage,
  LogoSection,
} from '../styles/mainStyles';
import { Quote } from '../components/Quote';
import { Footer } from '../components/Footer';
import { Case } from '../components/Case';

export const Design = ({ mainColor, secondaryColor }) => {
  return (
    <FWSection style={{ backgroundColor: secondaryColor }}>
      <ContentWrapper>
        <h1 style={{ color: mainColor }}>Design</h1>
        <Intro mainColor={mainColor}>
          Oavsett om det är en logotyp, bildspråk eller en färgskala med
          tillhörande typografi så skapar jag en visuell helhet av ert
          varumärke.{' '}
        </Intro>
        <InfoBoxesWrapper>
          <WhiteBox mainColor="var(--color-lavendarLilac)">
            <h2>Exempel på uppdrag</h2>
            <ul>
              <li>Visuell identitet</li>
              <li>Sociala medier/content</li>
              <li>Digital transformation</li>
              <li>Marknadsmaterial</li>
              <li>Redaktionell design</li>
              <li>Bokdesign</li>
              <li>Årsredovisningar</li>
            </ul>
          </WhiteBox>

          <div className="top-text">
            <h2>Filosofi</h2>
            <p style={{ lineHeight: '1.6' }}>
              När jag arbetar med design och uppbyggnad av ett varumärke arbetar
              jag intuitivt, riktat och handfast. Att hitta känslan i ett
              varumärke och sätta ett uttryck för detta är något jag brinner
              för.
            </p>
            <p style={{ lineHeight: '1.6', marginTop: '10px' }}>
              Jag jobbar mindre med tunga, abstrakta presentationsdokument och
              mer med skisser, känsla och snabba iterationer. Helhet är mitt
              signum och kvalitet är min drivkraft.
            </p>
            <h2 style={{ marginTop: '30px' }}>Verktyg</h2>
            <p style={{ lineHeight: '1.6' }}>
              Beroende på media rör jag mig i program som Figma, Photoshop,
              InDesign och Illustrator.
            </p>
          </div>
        </InfoBoxesWrapper>
        <LogoSection>
          <h2>Urval av kunder</h2>
          <div>
            <img src="./assets/logos/bonnier-svart.png" />
            <img src="./assets/logos/castellum-svart.png" />
            <img src="./assets/logos/hsb-svart.png" />
            <img src="./assets/logos/mama-svart.png" />
            <img src="./assets/logos/pe-svart.png" />
            <img src="./assets/logos/manpower-svart.png" />
            <img src="./assets/logos/plaza-svart.svg" />
            <img src="./assets/logos/spoon-svart.png" />
            <img src="./assets/logos/structsales-svart.png" />
            <img src="./assets/logos/wwl-svart.png" />
            <img src="./assets/logos/zooma-svart.png" />
            <img src="./assets/logos/zwapgrid-svart.png" />
          </div>
        </LogoSection>

        <SectionImage
          alt="Previous work"
          marginTop="50px"
          src="./assets/karin-nordkvist-earlier-work.jpg"
        />

        <Quote
          margins="100px 50px"
          sectionColor="var(--color-lavendarLilac)"
          quote='"Karin är som person väldigt lätt att arbeta med, hon anpassar snabbt sitt arbetssätt efter nya situationer och har en osviklig formkänsla. Karin är en god lyssnare, har en stark trendkänsla, och är en lagspelare. Jag skulle starkt rekommendera henne."'
          name="Vendela Nylund, fd. Art Director/mama - Bonnier"
        />

        <Case
          color={mainColor}
          name="CDCUL - Consumer Demand for Circular Urban Living"
          client="RISE"
          task="Webb & Design"
          imgSrc="./assets/cookie-cdcul-design.png"
          tech="Wordpress / Elementor"
          url="https://cdcul.eu/"
        />
        <Case
          color={mainColor}
          name="Plaza Kvinna"
          client="Plaza Publishing Group"
          task="Art direction/layout"
          imgSrc="./assets/cookie-plaza.png"
          text="Produktion av mode-, skönhets- och livsstilsmagasinet Plaza Kvinna med utgivning av 7 nummer per år."
        />
        <Case
          color={mainColor}
          name="Caroline Borg"
          client="Caroline Borg - stylist/fotograf/storyteller"
          task="Logotyp, identitet, webb"
          imgSrc="./assets/caroline-borg.png"
          url="https://carolineborg.se"
          text="För att visa upp Carolines fantastiska bilder designade jag den här sajten med fokus på just detta. Avskalad men ändå personlig typografi och en nedtonad, dov färgskala."
        />
        {/* <Case
          color={mainColor}
          name="Tvillingpodden"
          client="Jessica & Angelica Lagergren, Tvillingpodden"
          task="Logo & Identitet"
          imgSrc="./assets/tvillingpodden.jpg"
          text="Otroligt kul projekt där Lagergren-tjejernas ord fick ta plats och färgskalan lekfull."
        /> */}
        <Case
          color={mainColor}
          name="Hemma i HSB"
          client="Spoon/HSB"
          task="Art Direction/design"
          imgSrc="./assets/cookie-spoon-hsb.png"
          text="Fortfarande ett av mina absoluta favoritjobb där jag som AD för Hemma i HSB och HSB Uppdraget fick ta fram såväl ny profil som leda det löpande visuella arbetet."
        />
        <Case
          color={mainColor}
          name="Vattnet Går"
          client="Nina Campioni/Vattnet går"
          task="Identitet, webb, färg & typografi"
          imgSrc="./assets/vattnet-gar.jpg"
          url="https://instagram.com/vattnetgar"
          text="Ett projekt som började med ett nyhetsbrev och dess design, och slutade i full profil, ett flertal olika logotyper/image för de olika poddarna och en sajt."
        />
        {/* <Case color={mainColor} name="Doula Lagergren" client="Angelica Lagergren" task="Logo & Identitet" imgSrc="./assets/doula-lagergren-3.jpg" /> */}
        <Quote
          margins="0 50px 50px"
          sectionColor="var(--color-lavendarLilac)"
          quote='"Karin hade redan innan vårt första möte gjort research och hade koll på mitt varumärke, vilket gjorde det enkelt och snabbjobbat. Det var tydligt både i designen och i våra samtal att hon förstod mitt varumärke och hade stenkoll på målgruppen. Jag är helnöjd!"'
          name="Nina Campioni, grundare av Vattnet Går"
        />
        <Case
          color={mainColor}
          name="Castellum"
          client="Castellum AB"
          task="Identitet"
          imgSrc="./assets/castellum.png"
          text="Övergripande designarbete med fokus på en enad grafisk profil för fastighetsbolaget Castellum. Arbetet innefattade allt från typografi, bildspråk/bildsättning, strategiskt varumärkesarbete, produktion av skyltar, vepor, årsredovisning, referensmaterial och mycket mer."
        />
        <Case
          color={mainColor}
          name="Teenytiny"
          task="Branding/webbshop/sociala medier/strategi"
          imgSrc="./assets/teenytiny-1.jpg"
          text="Webbshop med produkter riktade mot föräldrar med små barn med ett intresse för hållbarhet. Ett projekt jag startade och drev tillsammans med en väninna, som synts i flera av Sveriges största inredningsmagasin."
          url="https://instagram.com/teenytinybrand/"
        />
        <Case
          color={mainColor}
          name="Mama"
          client="Bonnier"
          task="Formgivare/bildredaktör"
          imgSrc="./assets/mama.jpg"
          text="Arbetade i cirka 4 år med allt från formgivning och layout till friläggningar, repro och bildsättning."
        />
      </ContentWrapper>
      <Footer />
    </FWSection>
  );
};
